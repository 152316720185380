import Bugsnag from '@bugsnag/js'

export function start() {
  // next.js executes top-level code at build time. See https://github.com/vercel/next.js/discussions/16840 for further example
  // So use NEXT_PHASE to avoid Bugsnag.start being executed during the build phase
  // See https://nextjs.org/docs/api-reference/next.config.js/introduction and https://github.com/vercel/next.js/blob/canary/packages/next/shared/lib/constants.ts#L1-L5 for
  // more details on NEXT_PHASE
  if (process.env.NEXT_PHASE !== "phase-production-build" && process.env.NODE_ENV === 'production') {
    Bugsnag.start({
      apiKey: "bd2d234a4ff561e7815b455fd1eed749",//process.env.NEXT_PUBLIC_BUGSNAG_API_KEY,
      appVersion: process.env.NEXT_BUILD_ID,
      releaseStage: process.env.APP_ENV,
      // // @bugsnag/plugin-aws-lambda must only be imported on the server
      // plugins: [require('@bugsnag/plugin-aws-lambda')]
    })
  }
}
