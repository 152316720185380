import "@/styles/globals.scss";
import * as countries from "i18n-iso-countries";
import * as de from "i18n-iso-countries/langs/de.json";
import * as en from "i18n-iso-countries/langs/en.json";
import { appWithTranslation, useTranslation } from "next-i18next";
import type { AppProps } from "next/app";
import Head from "next/head";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { z } from "zod";
import { makeZodI18nMap } from "zod-i18n-map";
import { start } from "@/bugsnag";
import localFont from "next/font/local";

start();

const antonio = localFont({
  src: [
    {
      path: "../../public/fonts/antonio/Antonio-SemiBold.woff2",
      weight: "600",
      style: "normal",
    },
    {
      path: "../../public/fonts/antonio/Antonio-ExtraLight.woff2",
      weight: "200",
      style: "normal",
    },
    {
      path: "../../public/fonts/antonio/Antonio-Light.woff2",
      weight: "300",
      style: "normal",
    },
    {
      path: "../../public/fonts/antonio/Antonio-Regular.woff2",
      weight: "400",
      style: "normal",
    },
    {
      path: "../../public/fonts/antonio/Antonio-Bold.woff2",
      weight: "700",
      style: "normal",
    },
    {
      path: "../../public/fonts/antonio/Antonio-Thin.woff2",
      weight: "100",
      style: "normal",
    },
    {
      path: "../../public/fonts/antonio/Antonio-Medium.woff2",
      weight: "500",
      style: "normal",
    },
  ],
  display: "swap",
  variable: "--font-antonio",
});

const robotoCondensed = localFont({
  src: [
    {
      path: "../../public/fonts/roboto_condensed/RobotoCondensed-Bold.woff2",
      weight: "700",
      style: "normal",
    },
    {
      path: "../../public/fonts/roboto_condensed/RobotoCondensed-BoldItalic.woff2",
      weight: "700",
      style: "italic",
    },
    {
      path: "../../public/fonts/roboto_condensed/RobotoCondensed-Light.woff2",
      weight: "300",
      style: "normal",
    },
    {
      path: "../../public/fonts/roboto_condensed/RobotoCondensed-LightItalic.woff2",
      weight: "300",
      style: "italic",
    },
    {
      path: "../../public/fonts/roboto_condensed/RobotoCondensed-Regular.woff2",
      weight: "400",
      style: "normal",
    },
    {
      path: "../../public/fonts/roboto_condensed/RobotoCondensed-Italic.woff2",
      weight: "400",
      style: "italic",
    },
  ],
  display: "swap",
  variable: "--font-roboto-condensed",
});

interface AppPropsWithErr extends AppProps {
  err: any;
}

function App({ Component, pageProps, err }: AppPropsWithErr) {
  const { pathname } = useRouter();
  const queryClient = new QueryClient();
  countries.registerLocale(de);
  countries.registerLocale(en);
  // setupI18n();
  const { t } = useTranslation();
  z.setErrorMap(makeZodI18nMap({ t }));
  useEffect(() => {
    if (pathname.startsWith("/admin")) {
      const body = document.querySelector("body");
      if (body) {
        body.style.cssText = "background-color: #f6f6f6;";
      }
    }
  });
  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta httpEquiv="content-language" content="de" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta
          name="description"
          content="Alle Informationen und Hintergrundberichte zur besten Liga Europas."
          key="description"
        />
        <meta name="owner" content="" />
        <meta name="revisit-after" content="2days" />
        <meta
          name="og:title"
          content="Tischtennis Bundesliga - TTBL"
          key="og:title"
        />
        <meta
          name="og:description"
          content="Alle Informationen und Hintergrundberichte zur besten Liga Europas."
          key="og:description"
        />
        {/* TODO: Dynamic base url */}
        <meta
          name="og:image"
          content={`https://www.ttbl-staging.de/tischtennis_bundesliga_logo.png`}
          key="og:image"
        />
        <meta name="og:url" content="https://ttbl.de/" />
        <meta name="og:type" content="website" />
        <meta
          name="twitter:title"
          content="Tischtennis Bundesliga - TTBL"
          key="twitter:title"
        />
        <meta
          name="twitter:description"
          content="Alle Informationen und Hintergrundberichte zur besten Liga Europas."
          key="twitter:description"
        />
        <meta
          name="twitter:image"
          content="https://www.ttbl-staging.de/tischtennis_bundesliga_logo.png"
          key="twitter:image"
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@TTBuLi" />

        {/* Google tag (gtag.js) */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-WT0170VGM7"
        ></script>
        <script
          dangerouslySetInnerHTML={{
            __html: `window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-WT0170VGM7');`,
          }}
        />
      </Head>
      <QueryClientProvider client={queryClient}>
        <main
          className={`${antonio.variable} ${robotoCondensed.variable} font-roboto`}
        >
          <Component {...pageProps} err={err} />
        </main>
      </QueryClientProvider>
    </>
  );
}

export default appWithTranslation(App);
